import $ from 'jquery';
import 'what-input';
import objectFitImages from 'object-fit-images';
import * as ScrollMagic from "scrollmagic";
import gsap from "gsap";
import { TimelineMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, gsap);

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

jQuery(function ($) {

	contactModal();
	contentReveal();
	featuresCarousel();
	featuresCarouselGraphic();
	mastheadGraphicMobile();
	mastheadGraphicTablet();
	mastheadGraphicDesktop();
	objectFit();
	outlineReveal();

	function contentReveal () {
		// init controller
		var contentRevealCntrl = new ScrollMagic.Controller();

		// loop through all elements
		$('.fade-trigger').each(function() {

			// build Tween
			var contentReveal = new TimelineMax()
			.staggerFrom($(this).find('.fade-in'), 1, {
				y: "50%",
				opacity: 0,
				ease: "circ.out",
				stagger: {
					amount: .5
				}
			});

			// build scene
			new ScrollMagic.Scene({
				triggerElement: this,
				triggerHook: 1,
				reverse: false,
				offset: 83,
			})
			.setTween(contentReveal)
			.addTo(contentRevealCntrl);
		});
	}

	function contactModal() {

		var $form = $('#contactForm form');
		var $trigger = $('.button--contact');

		if ($form.length){
			$.ajax({
				url: '/mailer.php',
				type: 'GET',
				success: function(data) {
					$('<input>').attr({ type: 'hidden', name: 'token', value: data}).appendTo($form);
				}
			});
		};

		$trigger.on('click', function(e) {
			e.preventDefault();

			$form.show();
			$('.callout', $form).remove();
		});

		$form.on('submit', function(e) {
			e.preventDefault();
			var $fields = $(this).serialize();

			$.ajax({
				url: '/mailer.php',
				data: $fields,
				type: 'POST',
				dataType: 'json',
				success: function(data) {
					if (data.status == 'success') {
						$form.parent().append('<p class="callout success">' + data.message + '</p>');
						$form.remove();

					}
				},
				error: function(error) {
					$form.hide();
					$form.parent().append('<p class="callout success">An error has occured please try again.</p>');
				}
			});
		});
	}

	function featuresCarousel () {
		$('.features-carousel').slick({
			variableWidth: false,
			arrows: false,
			infinite: false,
			slidesToScroll: 1,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 539,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 1023,
					settings: {
						slidesToShow: 3,
					}
				}
			]
		});
	}

	function featuresCarouselGraphic () {
		$(window).bind("load", function() {
  			// Manage Animation
		    var animation = bodymovin.loadAnimation({
				container: document.getElementById('features-carousel-graphic'),
				renderer: 'svg',
				loop: false,
				autoplay: false,
				path: 'assets/data/Bottom.json'
			})

			// SCROLLING DOWN
		    var waypoint = new Waypoint({
		     element: document.getElementById('features-carousel-graphic'),
		     handler: function(direction) {
		       if (direction === 'down') {
		         animation.goToAndPlay(1, true);
		       }
		     },
		       offset: '70%'
		    })
		});
	}

	function mastheadGraphicMobile () {
		$(window).bind("load", function() {
		    var animation = bodymovin.loadAnimation({
				container: document.getElementById('masthead-graphic-mobile'),
				renderer: 'svg',
				loop: false,
				autoplay: true,
				path: 'assets/data/Mobile.json'
			})
		});
	}

	function mastheadGraphicTablet () {
		$(window).bind("load", function() {
		    var animation = bodymovin.loadAnimation({
				container: document.getElementById('masthead-graphic-tablet'),
				renderer: 'svg',
				loop: false,
				autoplay: true,
				path: 'assets/data/Tablet.json'
			})
		});
	}

	function mastheadGraphicDesktop () {
		$(window).bind("load", function() {
		    var animation = bodymovin.loadAnimation({
				container: document.getElementById('masthead-graphic-desktop'),
				renderer: 'svg',
				loop: false,
				autoplay: true,
				path: 'assets/data/Desktop.json'
			})
		});
	}

	function objectFit () {
		objectFitImages();
	}

	function outlineReveal () {
		// init controller
		var outlineRevealCntrl = new ScrollMagic.Controller();

		// build Tween
		var outlineReveal = new TimelineMax()
		.staggerFrom('.feature-wide__outline', 1, {
			y: "-30px",
			x: "-30px",
			ease: "circ.out",
			opacity: 0,
			delay: 1.4,
			stagger: {
				amount: .5
			}
		});

		// build scene
		new ScrollMagic.Scene({
			triggerElement: '.feature-wide__outline',
			triggerHook: 0.9,
			reverse: false,
		})
		.setTween(outlineReveal)
		.addTo(outlineRevealCntrl);
	}

});


$(document).foundation();
